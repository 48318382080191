@import "../../../utils/responsive.scss";

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-self: flex-start;
  align-items: center;
  margin-top: 0.5rem;
}

.innerContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.profilePicContainer {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
}

.userName {
  margin: 2rem 0 0 0;
  font-size: 2rem;
  font-weight: bold;
  color: rgb(0, 0, 0);
}

.tagline {
  margin: 0.3rem 0 0 0;
  font-size: 1.5rem;
  color: rgb(111, 111, 111);
  width: 90%;
  overflow-wrap: normal;
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
}

.settingsButton {
  width: 3rem;
}

.settingButtonContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.buttonText {
  color: black;
  margin-top: 0.5rem;
  font-size: 0.8rem;
  font-weight: bold;
  //display: none;
}

.smallIcon {
  height: 1.5rem;
}

.sideBySide {
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  align-items: end;
}

.simpleHeader {
  display: flex;
  justify-content: center;
}

.taglineIcon {
  width: 3rem;
  height: 3rem;
  padding-right: 1rem;
}

.outingBalloon {
  height: 2.5rem;
  width: 2.5rem;
  margin: 0.3rem 0.3rem;
  cursor: pointer;
}

.flakeIcon:hover::after {
  content: "Your Flake Rating indicates how many outings you have abandoned.";
  border: 1px solid lightgray;
  border-radius: 3px;
  font-weight: bold;
  background-color: grey;
  color: white;
  padding: 1rem;
  width: 11rem;
  position: absolute;
  top: 13rem;
  left: 2rem;
  z-index: 1;
  box-sizing: border-box;
}

.outingsIcon:hover::after {
  content: "Indicates how many outings you have completed.";
  border: 1px solid lightgray;
  border-radius: 3px;
  font-weight: bold;
  background-color: grey;
  color: white;
  padding: 1rem;
  width: 9rem;
  position: absolute;
  top: 13rem;
  right: 2rem;
  z-index: 1;
}

.tagline {
  box-sizing: border-box;
  padding: 0 2rem 2rem;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  font-size: 2.3rem;
  font-style: italic;
  color: black;
  /* background-color: $card_grey;
  border: 1px solid $light_grey;
  border-radius: 5px; */
}
