@import "../../../utils/responsive.scss";

.container{
    display: flex;
    flex-direction: column;
    width: 100%;
}

.input{
    width: 100%;
    padding: 1.4rem;
    box-sizing: border-box;
    font-size: 1.4rem;
    border: none;
    border-bottom: 3px solid transparent;
    outline: none;
    transition: border 0.2s ease-in-out;
    background-color: rgb(229, 238, 253);
    border-radius: 100rem;
  }
  
  .input:focus{
    outline: 1px solid $blue;
  }

  .label{
    font-size: 1.3rem;
    font-weight: bold;
    padding-left: 1rem;
    margin-top: 1rem;
  }
  