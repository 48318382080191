@import "../../../utils/responsive.scss";

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow-x: hidden;
  box-sizing: border-box;
  flex-shrink: 0;
  background-color: white;
  padding: 0rem 2rem;
  padding-top: 6rem;
}

.sideBySide {
  display: flex;
  margin-top: 1rem;
  width: 100%;
}

.spacer {
  width: 2rem;
  height: 1rem;
}

.applyButton {
  background-color: $blue;
  color: white;
  margin-bottom: 2rem;
  margin-right: 0.5rem;
}

.clearButton {
  margin-bottom: 2rem;
  margin-left: 0.5rem;
}

.inactiveButton {
  background-color: #f6f6f6;
  color: grey;
}

.inactiveButton:hover {
  cursor: none;
  opacity: 1;
}

.checkboxContainer {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  margin-top: 1rem;
  align-items: center;
}

.select {
  font-size: 1.2rem;
}

.categoryOption {
  background-color: white;
  padding: 1rem;
}

.categoryOption:hover {
  background-color: $card_grey;
}
