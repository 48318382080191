@import "../../../utils/responsive.scss";

.container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.button:hover {
  cursor: pointer;
}

.button {
  width: 100%;
  height: 100%;
  background: none;
  border: none;
  font-weight: inherit;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.iconButton {
  height: 80%;
  box-sizing: border-box;
}

.iconText {
  font-size: 1rem;
  font-weight: inherit;
  margin-top: 0.5rem;
  color: black;
  font-weight: bold;
}

.textButton {
  font-size: 1.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.blue {
  color: $blue !important;
}
