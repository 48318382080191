$breakpoint_s: 750px;
$breakpoint_m: 1100px;
$breakpoint_l: 1800px;
$central_span_width: 40rem;
$navbar_height: 9rem;
$blue: #6e9fed;
$light_blue: rgb(229, 238, 253);
$green: rgb(107, 189, 132);
$card_grey: rgb(250, 250, 250);
$button_grey: #f0f0f0;
$red: rgb(239, 122, 122);
$light_grey: rgb(223, 223, 223);
$zoom: 0.65;
$orange: rgb(255, 207, 94);

@mixin mbl_styles {
  @media (max-width: $breakpoint_s) {
    @content;
  }
}

@mixin sml_styles {
  @media (min-width: $breakpoint_s) and (max-width: $breakpoint_m) {
    @content;
  }
}

@mixin med_styles {
  @media (min-width: $breakpoint_m) and (max-width: $breakpoint_l) {
    @content;
  }
}

@mixin lrg_styles {
  @media (min-width: $breakpoint_l) {
    @content;
  }
}

@mixin responsive(
  $attribute,
  $t: "unset",
  $s: "unset",
  $m: "unset",
  $l: "unset"
) {
  @include mbl_styles {
    #{$attribute}: $t;
  }
  @include sml_styles {
    #{$attribute}: $s;
  }
  @include med_styles {
    #{$attribute}: $m;
  }
  @include lrg_styles {
    #{$attribute}: $l;
  }
}
