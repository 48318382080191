@import "../../../utils/responsive.scss";

.container {
  width: 100%;
  position: relative;
  box-sizing: border-box;
  padding-top: 1rem;
}

.upperContainer {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  background-color: white;
  width: 100%;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  margin-top: 0;
}

.upperCentralContainer {
  width: calc($central_span_width - 2rem);
  margin: 1rem;
  margin-bottom: 0;
}

.newChatButton {
  background-color: $blue;
  color: white;
  font-weight: bold;
  margin-bottom: 1rem;
  border-radius: 3px !important;
  height: 3.5rem;
  font-size: 1.3rem;
}

.noChatsMessage {
  padding: 2rem;
  width: 100%;
  display: flex;
  justify-content: baseline;
  text-align: center;
  box-sizing: border-box;
  flex-direction: column;
  align-items: center;
}
.noChatsMessageText {
  width: 70%;
  font-size: 1.3rem;
}
.noChatsMessageHeader {
  font-size: 1.8rem;
}
