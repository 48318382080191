@import "../../../utils/responsive.scss";


.container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  width: 100%;
  gap: 1px;
  background-color: white;
  margin-top: 1px;
}

.img {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
 
}
.img:hover{
    cursor: pointer;
    opacity: 0.8;
}

.imageContainer{
  position: relative;
}

.hidden{
  display: none;
}

