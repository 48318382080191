.container {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  padding-top: 2rem;
}

.sliderIconContainer {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  opacity: 0.3;
}

.sliderIconContainer:hover {
  cursor: pointer;
  opacity: 0.4;
}

.sliderIconContainer.selectedSlider:hover {
  cursor: pointer;
  opacity: 1;
}

.sliderIcon {
  width: 3rem;
  height: 3rem;
  display: flex;
  background-size: contain;
  margin-bottom: 0.1rem;
}

.sliderHighlight {
  background-color: rgb(0, 0, 0);
  width: 100%;
  height: 5px;
}

.selectedSlider {
  opacity: 1;
}

.iconName {
  margin-bottom: 1rem;
  //opacity: 0;
}
