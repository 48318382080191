@import '../../../utils/responsive.scss';

.container {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: all 0.1s ease-in-out;
    opacity: 0;
  }
  
  .blackout {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    opacity: 0.7;
    background-color: black;
  }

  .imageContainer{
    width: $central_span_width;
    height: $central_span_width;
    border-radius: 3px;
    position: relative;
    background-color: antiquewhite;
  }
  
  .image {
    position: absolute;
    width: 100%;
    border-radius: 2px;
  }

  .closeButton{
    position: absolute;
    top: 1rem;
    right: 1rem;
    width: 5rem;
    height: 3rem;
  }
  