@import "../../../utils/responsive.scss";

.linearLoaderContainer {
  width: 100%;
  height: 1rem;
  position: relative;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 10;
}

.linearLoader {
  width: 100%;
  height: 3px;
  position: absolute;
  background: $blue;
  animation: loading 1.3s infinite ease-in-out;
}

@keyframes loading {
  0% {
    left: -100%;
  }
  100% {
    left: 100%;
  }
}
