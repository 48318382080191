@import "../../../utils/responsive.scss";

.container {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  position: relative;
  width: 100%;
}


.valueContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.4rem;
  border-radius: 100rem;
  font-size: 1.4rem;
}

.valueContainer:active{
  outline: 1px solid $blue;
}

.value {
  width: 100%;
}

.label {
  font-size: 1.3rem;
  font-weight: bold;
  margin-left: 1rem;
}

.arrow {
  height: 1.5rem;
  width: 1.5rem;
}

.arrow .label {
  margin-bottom: 0.5rem;
  width: 100%;
}

.select {
  width: 100%;
  background-color: rgb(229, 238, 253);
  box-sizing: border-box;
  border-radius: 100rem;
}

.optionContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}

.optionContainer:hover {
  cursor: pointer;
}

.optionsContainer {
  position: absolute;
  left: 2rem;
  width: calc(100% - 4rem);
  box-sizing: border-box;
  border: 1px solid #c1c1c1;
  z-index: 1;
}

.optionComponentContainer {
  font-size: 1.3rem;
  background-color: white;
  padding: 1rem;
  width: 100%;
  box-sizing: border-box;
}

.optionComponentContainer:hover {
  cursor: pointer;
  background-color: $card_grey;
}

.name {
  font-weight: bold;
}

.details {
  margin-top: 0.2rem;
  font-size: 1.1rem;
  font-weight: normal;
}

.unselectable {
  color: grey !important;
  border-top: 1px solid $light_grey;
}
