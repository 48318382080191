@import "../../../utils/responsive.scss";

.container {
  display: flex;
  /*  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px; */
  border: 1px solid $light_grey;
  box-sizing: border-box;
  margin-bottom: 2rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 2rem;
  background-color: $card_grey;
  border-radius: 3px;
}

.header {
  font-size: 1.6rem;
  font-weight: bold;
}

.buttons {
  display: flex;
  width: 100%;
  margin-top: 1rem;
}

.buttonSpacer {
  width: 3rem;
  height: 1rem;
}

.acceptButton {
  background-color: $green;
  color: white;
}
