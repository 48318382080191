@import "../../../utils/responsive.scss";

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow-x: hidden;
  box-sizing: border-box;
  flex-shrink: 0;
  background-color: white;
  padding: 0rem 2rem;
  padding-top: 7rem;
}


.addButton {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 3.5rem;
  margin-right: 1rem;
  width: 4rem;
}

.headerSubtext {
  font-size: 1.3rem;
  margin-bottom: 2rem;
}

.dailyMatchesContainer {
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  width: 100%;
  box-sizing: border-box;
  padding: 2rem;
  background-color: $card_grey;
  border: 1px solid $button_grey;
}

.matchesHeader {
  margin-top: 0;
  font-size: 1.8rem;
}
