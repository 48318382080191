@import "../../../utils/responsive.scss";

.iconBacker {
  background-color: $card_grey;
}

.outerContainer {
  display: flex;
  width: 100%;
  border: none;
  outline: none;
  background-color: transparent;
  border-top-right-radius: 100rem;
  border-bottom-right-radius: 100rem;
  border-top-left-radius: 6rem;
  border-bottom-left-radius: 6rem;
  /* box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px; */
  background-color: transparent;
  margin-bottom: 1rem;
}

.innerContainer:hover {
  cursor: pointer;
  background-color: rgb(243, 243, 243);
  border-radius: inherit;
}

.categoryStripe {
  width: 1rem;
  height: 100%;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

.innerContainer {
  width: 100%;
  flex-grow: 1;
  box-sizing: border-box;
  background-color: rgb(250, 250, 250);
  border-left: none;
  border-radius: inherit;
  border: 1px solid $light_grey;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left: 0;
}

.flakedInnerContainer {
  width: 100%;
  flex-grow: 1;
  box-sizing: border-box;
  border-left: none;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  background-color: rgb(255, 203, 203);
}

.flakedInnerContainer:hover {
  cursor: pointer;
  background-color: rgb(255, 180, 180);
}

.upperSection {
  width: 100%;
  padding: 1rem;
  box-sizing: border-box;
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
  background-color: inherit;
  border-radius: inherit;
}

.leftUpperSection {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.rightUpperSection {
  display: flex;
  background-color: inherit;
  border-radius: inherit;
  padding-right: 0.5rem;
}

.nameStatusContainer {
  display: flex;
  color: black;
  align-items: center;
}

.nameActivityContainer {
  display: flex;
  flex-direction: column;
}

.name {
  font-size: 1.2rem;
}

.activity {
  font-size: 1.5rem;
  font-weight: bold;
}

.status {
  font-size: 1rem;
  color: rgb(96, 96, 96);
  margin-top: 0.5rem;
}

.userIconContainer {
  margin-left: 1rem;
  border-radius: 100rem;
}
