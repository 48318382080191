@import "../../../utils/responsive.scss";

.outer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  align-items: center;
  overflow-x: hidden;
  box-sizing: border-box;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
  overscroll-behavior: none;
  margin-bottom: $navbar_height;
}

.outer::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
  box-sizing: border-box;
  width: 100%;
  padding: 1rem;
  padding-top: 0;
  overscroll-behavior: none;

  @include responsive(
    "width",
    $central_span_width,
    $central_span_width,
    $central_span_width,
    $central_span_width
  );
}

.mainContainerChildren {
  width: 100%;
  display: flex;
  justify-content: center;
}

.inner::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}
