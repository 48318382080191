.container {
  width: 100%;
  font-weight: bold;
  font-size: 1.3rem;
  display: flex;
  padding: 1.4rem;
  box-sizing: border-box;
  justify-content: center;
  outline: none;
  border: none;
  border-radius: 100rem;
  align-items: center;
  color: black;
}

.container:hover {
  cursor: pointer;
}

.greyButton {
  color: rgb(111, 111, 111);
  background-color: rgb(223, 223, 223);
  margin: 2rem 0 2rem 0;
}
