@import "../../../utils/responsive.scss";


.container{
  background-color: inherit;
  border-radius: 100rem;
}

.pieChart {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100rem;
}

.photo {
  border-radius: 1000px;
  background-size: cover;
  box-sizing: border-box;
}

.whiteBacker {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100rem;
  box-sizing: border-box;
  position: relative;
  background-color: inherit;
}

.imageLoading {
  position: absolute;
  border-radius: 100rem;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.spinner {
  display: inline-block;
  position: relative;
  width: 90%;
  height: 90%;
}
.spinner div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  border: 0.2rem solid #3d3d3d;
  border-radius: 50rem;
  animation: spinner 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #3d3d3d transparent transparent transparent;
}
.spinner div:nth-child(1) {
  animation-delay: -0.45s;
}
.spinner div:nth-child(2) {
  animation-delay: -0.3s;
}
.spinner div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.badgeContainer{
  width: 25%;
  height: 25%;
  position: absolute;
  background-color: rgb(255, 255, 255);
  bottom: 1rem;
  right: 1rem;
  border-radius: 100rem;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.badgeContent{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 83%;
  height: 83%;
  background-color: $blue;
  border-radius: 100rem;
}

.placeholderImage{
  background-color: $card_grey;
  border-radius: 100rem;
  display: flex;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}

.loaderSpinner{
  filter: invert(0.2);
}

