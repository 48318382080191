@import "../../../utils/responsive.scss";

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow-x: hidden;
  box-sizing: border-box;
  flex-shrink: 0;
  background-color: white;
  padding: 1rem 1rem 0rem 1rem;
  width: 100%;
}

.photoContainer {
  display: flex;
  width: 100%;
  align-items: center;
  box-sizing: border-box;
  border-radius: 3px;
  position: relative;
  justify-content: space-evenly;
  height: fit-content;
  flex-direction: column;
}

.imageDisplayContainer{
  display: flex;
  position: relative;
}

.photoEditor {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: fit-content;
}

.formSpacer {
  width: 2rem;
}

.sideBySide {
  display: flex;
  width: 100%;
  margin-bottom: 1rem;
}

.saveButton {
  background-color: $blue;
  color: #fff;
  border: none;
  padding-left: 2rem;
  padding-right: 2rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 1.2rem;
  width: fit-content;
}

.unclickableButton {
  background-color: $button_grey;
  color: #bbbbbb;
  padding-left: 2rem;
  padding-right: 2rem;
  border: none;
  cursor: auto;
  font-size: 1.2rem;
  box-shadow: none !important;
  width: fit-content;
}

.unclickableButton:hover {
  cursor: not-allowed;
  opacity: 1;
}

.cropButton {
  background-color: $button_grey;
  color: #000000;
  border: none;
  padding: 1rem;
  cursor: pointer;
  font-size: 16px;
  width: 100%;
  font-size: 1.2rem;
}

.uploadButton {
  border: none;
  cursor: pointer;
  width: 4rem;
  height: 4rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.uploadButton:hover {
  opacity: 0.6;
}

.noClick{
  opacity: 0.3;
  cursor: not-allowed !important;
}

.buttonIcon {
  width: 2.8rem;
  height: 2.8rem;
  margin-bottom: 0.5rem;
}

.revertButton {
  background-color: $button_grey;
  color: black;
  border: none;
  margin-top: 1rem;
  cursor: pointer;
  width: 100%;
}

.locationSelect {
  font-size: 1.3rem;
  margin-bottom: 1rem;
}

.uploadInput {
  display: none;
}

.pieChart {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100rem;
}

.editButton{
  width: 45%;
}



