@import "../../../utils/responsive.scss";

.container {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  text-align: left;
  padding: 1rem;
  box-sizing: border-box;
}

.header {
  font-size: 1.5rem;
  font-weight: bold;
  width: 100%;
  margin-bottom: 1rem;
}

.message {
  font-size: 1.2rem;
  margin-bottom: 2rem;
}

.buttonContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.buttonSpacer {
  width: 2rem;
  height: 1rem;
}

.okButton {
  background-color: $blue;
  color: white;
}

.cancelButton {
  background-color: $button_grey;
}

.deleteButton{
  background-color: $red;
  color: white;
  font-weight: bold;
}

.unclickableButton {
  background-color: $button_grey;
  color: grey;
  cursor: not-allowed !important;
  box-shadow: none !important;
}

.unclickableButton:hover {
  cursor: not-allowed !important;
  background-color: $button_grey !important;
}
