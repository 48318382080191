@import "../../../utils/responsive.scss";

.container {
  display: flex;
  position: absolute;
  justify-content: center;
  font-size: 1.2rem;
  padding: 1rem;
  background-color: rgb(255, 255, 255);
  border-bottom: 3px solid $red;
  z-index: 1;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  border: 1px solid $light_grey;
  max-width: calc($central_span_width / 2);
  text-wrap: wrap;
}

.arrow {
  width: 0;
  height: 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
    position: absolute;
  border-bottom: 15px solid $light_grey;
  top: -15px;
  left: 1rem;
}

.arrowRight {
  width: 0;
  height: 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
    position: absolute;
  border-bottom: 15px solid $light_grey;
  top: -15px;
  right: 1rem;
}

.exclamation{
    background-color: $orange;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.3rem;
    font-weight: bold;
    width: 1.5rem;
    height: 1.5rem;
    border: 1px solid $light_grey;
    margin-right: 0.5rem;
    border-radius: 2px;
}

.message{
    width: calc(100% - 3rem);
    margin-top: 0.1rem;
    flex-grow: 1;
}
