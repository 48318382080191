@import "../../../utils/responsive.scss";

.container {
  display: flex;
  flex-direction: column;
  padding: 0rem 2rem;
  width: 100%;
  box-sizing: border-box;
  background-color: white;
  padding-bottom: 4rem;
  padding-top: 9rem;
}

.header {
  display: flex;
  flex-direction: column;
}

.headerInnerContainer {
  display: flex;
  flex-direction: column;
  padding-left: 2rem;
}

.sideBySide {
  display: flex;
  justify-content: space-between;
}

.buttonSpacer {
  display: flex;
  width: 4rem;
  height: 1rem;
}

.chatButton {
  //background-color: $blue;
  //color: white;
  //font-weight: bold;
  width: 100%;
}

.completedButton {
  background-color: $blue;
  color: white;
  font-weight: bold;
  width: 100%;
}

.leaveButton {
  margin-top: 3rem;
  background-color: $red;
  color: white;
  font-weight: bold;
}

.outingName {
  font-size: 1.8rem;
  font-weight: bold;
}

.outingStatus {
  font-size: 1.4rem;
  color: grey;
}

.outingsIcon {
  width: 90%;
  margin: 1rem 1rem 2rem;
}

.outingPopup {
  font-size: 1.2rem;
  text-align: center;
  width: 100%;
}

.sectionHeader {
  font-size: 1.6rem;
  font-weight: bold;
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 2rem;
}

.sectionHeaderIcon {
  height: 3rem;
  width: 3rem;
  margin-right: 2rem;
}

.noPhotosMessage {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  text-align: center;
  //background-color: $card_grey;
  padding: 2rem;
  border-radius: 3px;
  box-sizing: border-box;
}

.noPhotosMessageText {
  font-size: 1.1rem;
  color: grey;
  width: 70%;
  padding-bottom: 1rem;
}

.noPhotosMessageHeader {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.deleteHelp {
  margin-top: 1rem;
  font-size: 1.2rem;
  color: grey;
  width: 100%;
  text-align: center;
}

.warningContainer {
  padding: 0;
  box-sizing: border-box;
}

.warningText {
  font-size: 1.2rem;
}

.warningName {
  font-size: 1.8rem;
  font-weight: bold;
  padding: 1rem 0;
}

.flakeContainer {
  display: flex;
  align-items: center;
  width: 100%;
  background-color: $card_grey;
  box-sizing: border-box;
  padding: 1rem;
  background-color: rgb(255, 218, 218);
  border-radius: 3px;
}

.flakeIcon {
  height: 4rem;
  margin-right: 1rem;
}

.flakeText {
  width: 100%;
}

.photosHeaderText {
  font-size: 1.2rem;
  color: grey;
  font-weight: normal;
}

.uploadPhotosContainer {
  margin-bottom: 1rem;
}

.uploadInput {
  display: none;
}

.addPhotosButton {
  width: 100%;
  margin: 1rem 0;
}

.completionMessage {
  font-size: 1.2rem;
  padding: 1rem;
  font-weight: bold;
}

.unclickableButton {
  background-color: $button_grey;
  color: grey;
  cursor: not-allowed;
  box-shadow: none !important;
}

.unclickableButton:hover {
  cursor: default !important;
  background-color: $button_grey !important;
}

.rateActivityHeader{
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
    
}

.rateActivityName{
    color: rgb(56, 56, 56);
    font-size: 1.3rem;
    margin-bottom: 2rem;
    padding: 0.5rem 1.5rem;
    border-radius: 3px;
}

.feedCardContainer{
  display: flex;
  flex-direction: column;
  padding: 2rem;
  min-height: 60vh;
}

.headerInnerContainerFeedCard{
  display: flex;
  flex-direction: column;
  padding-left: 2rem;
}
