@import "../../../utils/responsive.scss";

.container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  margin-bottom: 1rem;
  height: 3.5rem;
  box-sizing: border-box;
}

.input {
  outline: none;
  border: 1px solid $light_grey;
  border-right: none;
  padding: 1rem;
  width: 100%;
  box-sizing: border-box;
  background-color: $card_grey;
  font-size: 1.3rem;
  height: 100%;
  border-radius: 0;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

.searchIconContainer {
  height: 100%;
  box-sizing: border-box;
  border-radius: 0;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

.searchIcon {
  height: 100%;
  padding: 28%;
  background-color: $card_grey;
  border: 1px solid $light_grey;
  border-left: none;
  box-sizing: border-box;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}
