@import "../../../utils/responsive.scss";

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 3px;
  margin-bottom: 1rem;
}

.topContainer {
  display: flex;
  width: 100%;
  flex-direction: column;
  box-sizing: border-box;
}

.topInnerContainer {
  display: flex;
  background-color: $card_grey;
  padding: 1rem;
  width: 100%;
  justify-content: space-between;
  box-sizing: border-box;
  border: 1px solid $light_grey;
  border-top: none;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}

.colorStripe {
  height: 0.8rem;
  min-width: 100%;
  flex-grow: 1;
}

.outingDetailsContainer {
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
}

.activityName {
  font-size: 1.3rem;
  color: grey;
  font-weight: bold;
  margin-top: 0.2rem;
}

.outingMembers {
  font-size: 1.8rem;
  font-weight: bold;
}

.buttonsContainer {
  display: flex;
  width: 100%;
  margin-top: 0.5rem;
}

.button {
  margin-right: 1rem;
  width: fit-content;
}

.innerContainerLeft {
  display: flex;
  background-color: inherit;
}

.likeIcon {
  height: 3rem;
  margin-left: 1rem;
  margin-right: 0.5rem;
  cursor: pointer;
}

.viewActivityButton {
  width: fit-content;
}

.friendsPopupContainer {
  display: flex;
  flex-direction: column;
  padding: 2rem;
  background-color: white;
  border-radius: 3px;
}

.friendsPopupHeader {
  margin: 1rem 0 2rem 0;
  font-size: 1.6rem;
  font-weight: bold;
}

.loadingTile {
  padding: 1rem;
  background-color: $card_grey;
  display: flex;
  border-top-right-radius: 3px;
  border-bottom-left-radius: 3px;
}
