@import "../../../utils/responsive.scss";

.container {
  display: flex;
  flex-direction: column;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;
  background-color: white;
  width: 100%;
}

.container::-webkit-scrollbar {
  display: none;
  width: 0;
  height: 0;
}

.outingContainer {
  display: flex;
  background-color: transparent;
  border-radius: 3px;
}

.completedHeader{
  font-size: 1.4rem;
  margin-bottom: 1.5rem;
  font-weight: bold;
  
}

.pendingContainer {
  display: flex;
  flex-direction: column;
  padding-bottom: 1rem;
}

.headingBlurb {
  font-size: 1rem;
  color: grey;
  margin-top: -1rem;
}
