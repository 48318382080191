@import "../../../utils/responsive.scss";

.iconBacker {
  background-color: $card_grey;
}

.container {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  /*   background-color: $card_grey;*/
  margin-bottom: 1rem;
  justify-content: space-between;
  box-sizing: border-box;
  border-radius: 3px;
  width: 100%;
  max-height: 16rem;
  transition: all 0.15s ease-in-out;
  /* box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px; */
}

.upperContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.container:hover {
  cursor: pointer;
  opacity: 0.9;
}

.detailsContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
}

.userName {
  font-size: 1.5rem;
  font-weight: bold;
  margin-left: 1rem;
}

.location {
  font-size: 1.1rem;
  color: grey;
}

.ratingsContainer {
  display: flex;
  margin: 0.5rem 0 0 0;
}

.friendLoading {
  padding: 2rem;
  margin-bottom: 1rem;
  width: 100%;
  font-size: 1.4rem;
  font-weight: bold;
  background-color: rgb(250, 250, 250);
}

.leftContainer {
  display: flex;
  flex-grow: 1;
}

.readyBadge {
  background-color: $green;
  width: 100%;
  height: 100%;
  border-radius: 100rem;
}

.busyBadge {
  background-color: $red;
  width: 100%;
  height: 100%;
  border-radius: 100rem;
}
