@import "../../../utils/responsive.scss";

.header {
  align-items: center;
  background-color: #ffffff;
  justify-content: space-evenly;
  display: flex;
  width: 100%;
  height: $navbar_height;
  box-sizing: border-box;
  position: fixed;
  z-index: 3;
  bottom: 0;
  background-color: $card_grey;
  border-top: 1px solid $light_grey;
}

.innerContainer {
  padding: 1rem 1rem 2rem 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: $central_span_width;
  position: relative;

  @include responsive(
    "width",
    $central_span_width,
    $central_span_width,
    $central_span_width,
    $central_span_width
  );
}
.none {
  width: 100%;
}

.navItems {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 0 1rem;
  margin-bottom: 1rem;
}

.username {
  font-size: 1.5rem;
  font-weight: bold;
  margin-left: 1rem;
}

.navButton {
  height: 3.2rem;
  position: relative;
}

.logoButton {
  width: 4rem;
  height: 4rem;
}

.activeLink {
  position: relative;
  border-radius: 4px;
  color: $blue !important;
}

.goButton {
  display: flex;
  align-items: center;
  font-size: 1.1rem;
  font-weight: inherit;
  flex-direction: column;
  border-radius: 300px;
}

.bellIcon {
  height: 2.5rem;
}

.bellIcon:hover {
  cursor: pointer;
}

.notificationBadge {
  display: flex;
  position: absolute;
  top: -0.3rem;
  right: 0rem;
  width: 1.8rem;
  height: 1.8rem;
  background-color: $red;
  color: white;
  font-weight: bold;
  justify-content: center;
  align-items: center;
  border-radius: 100rem;
}

.notificationsButton {
  outline: none;
  border: none;
  background-color: transparent !important;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.logo {
  height: 5rem;
}

.subPromptButton{
  opacity: 0;
  position: fixed;
  z-index: -2;
}
