.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow-x: hidden;
    box-sizing: border-box;
    flex-shrink: 0;
    background-color: white;
    padding: 0rem 2rem;
    padding-top: 7rem;
  }
  
  
.removeButton {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 3.5rem;
    margin-right: 1rem;
    width: 4rem;
  }
