@import "../../../utils/responsive.scss";

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow-x: hidden;
  box-sizing: border-box;
  flex-shrink: 0;
  background-color: white;
  padding: 7rem 1rem 0 1rem;
}

.saveButton {
  background-color: $blue;
  color: #fff;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 100%;
  font-size: 1.2rem;
}

.statusSelect {
  font-size: 1.3rem;
  margin-bottom: 1rem;
}

.statusOptionContainer {
  font-size: 1.3rem;
  background-color: white;
  padding: 1rem;
  width: 100%;
  box-sizing: border-box;
}

.statusOptionContainer:hover {
  cursor: pointer;
  background-color: $card_grey;
}

.statusName {
  font-weight: bold;
}

.statusDetails {
  margin-top: 0.2rem;
  font-size: 1.1rem;
  font-weight: normal;
}

.unselectable{
  color: grey !important;
  border-top: 1px solid $light_grey;
}

.logoutButton{
  background-color: $red;
  width: calc(100% - 2rem);
  color: white;
  margin: 1rem 2rem 4rem 2rem ;
}

.installButton{
  background-color: $button_grey;
  width: calc(100% - 2rem);
  color: black;
  margin: 1rem 2rem 2rem 2rem ;
}