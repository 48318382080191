@import "../../../utils/responsive.scss";

.outerContainer {
  display: flex;
  width: 100%;
  margin-bottom: 1rem;
  border-radius: 3px;
  /*   box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px; */
  box-sizing: border-box;
  border: 1px solid $light_grey !important;
  border-left: none !important;
  border-radius: 5px;
}

.categoryStripe {
  width: 1rem;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

.innerContainer {
  width: 100%;
  flex-grow: 1;
  box-sizing: border-box;
  background-color: $card_grey;
  border-left: none;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  border-left: none !important;
  position: relative;
}

.upperContainer {
  border: none;
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  box-sizing: border-box;
  padding-top: 100%;
}

.name {
  font-size: 1.7rem;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
}

.innerNameContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
  align-items: baseline;
  width: 100%;
}

.location {
  font-size: 1.1rem;
  color: black;
  width: 100%;
}

.category {
  margin-left: 0.4rem;
  font-size: 1.1rem;
  color: grey;
}

.select {
  background-color: $blue;
  width: 14rem;
  color: white;
  display: flex;
  align-items: center;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 3px !important;
}

.remove {
  background-color: $red;
  width: 7rem;
  color: white;
  display: flex;
  align-items: center;
  border-radius: 0;
  border-bottom-right-radius: 3px;
}

.description {
  font-size: 1.4rem;
  color: rgb(0, 0, 0);
  margin-top: 0.5rem;
}

.specsContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 1.5rem;
  padding: 0 2rem;
}

.buttonContainer {
  display: flex;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 3.7rem;
  border-bottom-right-radius: 4px;
}

.buttonSpacer {
  width: 100%;
  height: 3.7rem;
}

.instructionsContainer {
  display: flex;
  flex-direction: column;
  transition: all 0.5s ease;
  overflow-y: hidden;
  box-sizing: border-box;
  width: 100%;
}

.iHeader {
  margin: 1rem;
  font-size: 1.5rem;
  font-weight: bold;
}

.spacer {
  width: 1.5rem;
}

.addInstructionButton {
  box-sizing: border-box;
  width: calc(100% - 2rem);
  margin: 0 1rem;
}

.goalContainer {
  display: flex;
  padding: 0rem 1rem 1rem 1rem;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
}

.goalIcon {
  height: 3rem;
  padding: 1rem 1rem 1rem 1rem;
}

.goal {
  font-size: 1.4rem;
  font-weight: bold;
  width: 100%;
}

.completedIcon {
  height: 2.2rem;
  margin-left: 1rem;
}

.nameRightContainer {
  display: flex;
}

.completedIconContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.deleteActivityButton {
  margin-left: 1rem;
}

.warningContainer {
  padding: 0;
  box-sizing: border-box;
}

.warningText {
  font-size: 1.2rem;
}

.warningName {
  font-size: 1.8rem;
  font-weight: bold;
  padding: 1rem 0;
}

.photoContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  opacity: 0.9;
}

.nonPhotoContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  filter: invert(1);
  padding: 1rem;
  box-sizing: border-box;
  background-image: linear-gradient(to top, rgba(0, 0, 0, 0), rgb(222, 222, 222) 93%);
}

.buttonGradient{
  background-image: linear-gradient(to bottom, transparent, rgb(60, 60, 60) 95%);;
}
