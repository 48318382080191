@import "../../../utils/responsive.scss";

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow-x: hidden;
  box-sizing: border-box;
  flex-shrink: 0;
  background-color: white;
  padding: 0rem 2rem;
  padding-top: 8rem;
}

.createForm {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.locationSelect {
  font-size: 1.3rem;
  margin-bottom: 1rem;
  width: 100%;
}

.categoryInput {
  margin-bottom: 1rem !important;
}

.createButton{
    background-color: $blue;
    color: white !important;
    width: fit-content;
}

.iconInput{
    margin-top: 0.5rem;
}

