@import "../../../utils/responsive.scss";

.outerContainer {
  display: flex;
  box-sizing: border-box;
  margin-bottom: 1.2rem;
  /* box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px; */
  border-radius: 3px;
}

.container {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  background-color: $card_grey;
  position: relative;
  padding: 1rem;
  box-sizing: border-box;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  border: 1px solid $light_grey;
  border-left: 0;
}

.rightContainer {
  display: flex;
}

.categoryStripe {
  width: 1rem;
  height: auto;
  padding-bottom: 1rem;
  border-bottom-left-radius: 3px;
  border-top-left-radius: 3px;
}

.container:hover {
  cursor: pointer;
  background-color: rgb(242, 242, 242);
}

.leftSection {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.iconClusterContainer {
  margin-right: 2rem;
  background-color: inherit;
}

.name {
  font-size: 1.6rem;
  font-weight: bold;
}

.memberNames {
  font-size: 1.3rem;
}

.nonUserMembers {
  font-size: 1.6rem;
  font-weight: bold;
}

.notificationBadge {
  display: flex;
  position: absolute;
  top: -10px;
  right: -8px;
  width: 2.5rem;
  height: 2.5rem;
  background-color: $red;
  color: white;
  font-weight: bold;
  font-size: 1.1rem;
  justify-content: center;
  align-items: center;
  border-radius: 100rem;
}

.outingActivity {
  font-size: 1.2rem;
  font-weight: bold;
  color: grey;
  margin-bottom: 0.6rem;
}
