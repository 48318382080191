@import "../../../utils/responsive.scss";

.centralSpanVariable {
  width: $central_span_width;
  display: none;
}

.container {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  background-color: white;
  width: $central_span_width;
  transition: margin 0.23s ease-in-out;
  background-color: rgb(255, 255, 255);
  box-sizing: border-box;
  position: absolute;
  height: 100%;
  overscroll-behavior: none;
}

.leftContainer{
  display: flex;
  align-items: center;
}

.header {
  display: flex;
  width: 100%;
  padding: 2rem;
  padding-left: 13rem;
  font-size: 2rem;
  font-weight: bold;
  height: 3.8rem;
  font-family: Arial, Helvetica, sans-serif;
  align-items: center;
  //background-color: $card_grey;
}

.modalHeader {
  display: flex;
  justify-content: space-between;
  position: fixed;
  top: 0;
  align-items: center;
  width: $central_span_width;
  background-color: white;
  font-size: 2rem;
  padding: 1.5rem;
  z-index: 3;
  font-weight: bold;
  box-sizing: border-box;
  margin-top: 0
}

.outerContainer {
  position: fixed;
  box-sizing: border-box;
  display: flex;
  height: calc(100% - $navbar_height);
  width: calc(2 * $central_span_width);
  margin-left: $central_span_width;
  overflow-y: scroll;
  overscroll-behavior: none;
  top: 0
}

.baffle {
  background-color: rgb(255, 255, 255);
  height: 100%;
  width: calc((100% - $central_span_width) / 2);
  right: 0;
  position: fixed;
  z-index: 4;
}

.backButton {
  width: 3.5rem;
  height: 3.5rem;
  margin-right: 1.5rem;
}

.backButton:hover {
  cursor: pointer;
  opacity: 0.5;
}

.arrowImage {
  width: 100%;
  height: 100%;
}

.headerButton{
  width: fit-content;
  background-color: $blue;
  color: white;
  font-weight: bold;
  margin-right: 0.5rem;
}
