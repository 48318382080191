@import "../../../utils/responsive.scss";

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  flex-grow: 1;
  max-height: 100%;
  overflow-y: scroll;
}

.chatContainer {
  flex-grow: 1;
  box-sizing: border-box;
  background-color: $card_grey;
  overflow-y: scroll;
  border: 1px solid $light_grey;
}

.iconBacker {
  background-color: $button_grey;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  box-sizing: border-box;
  border-left: 1px solid $light_grey;
  border-right: 1px solid $light_grey;
}

.headerRightContainer {
  display: flex;
  padding: 1rem;
  box-sizing: border-box;
  background-color: inherit;
}

.headerLeftContainer {
  display: flex;
  flex-direction: column;
  margin-left: 7rem;
  height: 100%;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}

.lastActive {
  font-size: 1rem;
  color: grey;
  margin-bottom: 1rem;
}

.chatName {
  font-size: 1.6rem;
  font-weight: bold;
  margin-top: 2rem;
}

.chatMembers {
  font-size: 1.3rem;
  color: rgb(90, 90, 90);
}

.composeContainer {
  background-color: $button_grey;
  padding: 1rem;
  box-sizing: border-box;
  display: flex;
  padding-bottom: 2rem;
  border-left: 1px solid $light_grey;
  border-right: 1px solid $light_grey;
  border-bottom: none;
  
}

.composer {
  padding: 1rem;
  border-radius: 4px;
  background-color: $card_grey;
  width: 100%;
  box-sizing: border-box;
  font-size: 1.3rem;
  outline: none !important;
  resize: none;
  font-family: Arial, Helvetica, sans-serif;
  border: 1px solid $light_grey;
  overflow-y: visible;
}

.sendButton {
  background-color: $button_grey;
  border: none;
  outline: none;
  width: 4rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
  justify-content: flex-end;
  align-items: center;
}

.sendIcon {
  width: 2rem;
  margin-bottom: 0.5rem;
}

.sendIcon:hover {
  cursor: pointer;
  opacity: 0.7;
}

.chatContainer {
  display: flex;
  flex-direction: column-reverse;
  padding: 1rem;
  box-sizing: border-box;
}

.bubbleContainer {
  display: flex;
  width: 100%;
  padding: 1rem 0;
  flex-direction: row-reverse;
  box-sizing: border-box;
  margin-bottom: 1rem;
}

.bubbleContainerForeign {
  display: flex;
  width: 100%;
  padding: 1rem 0;
  box-sizing: border-box;
  margin-bottom: 1rem;
}

.bubble {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  border-radius: 3px;
  max-width: 80%;
  
}

.bubbleUser {
  font-size: 1rem;
  color: grey;
  margin-bottom: 0.4rem;
}

.bubbleMessage {
  font-size: 1.2rem;
}

.bubbleTail {
  border-left: 15px solid transparent;
  border-bottom: 15px solid transparent;
  position: absolute;
  bottom: -20px;
  right: 1rem;
  
}

.bubbleTailForeign {
  border-right: 15px solid transparent;
  border-bottom: 15px solid transparent;
  position: absolute;
  bottom: -20px;
  left: 1rem;
}

.sent {
  font-size: 0.9rem;
  color: grey;
  margin-top: 0.5rem;
}

.nonUserMembers {
  padding-top: 0.5rem;
  font-size: 1.8rem;
  font-weight: bold;
  color: black;
}

.lastReadIndicator {
  margin-top: 1rem;
  font-size: 1.2rem;
  color: $light_grey;
  display: flex;
  width: 100%;
  justify-content: center;
  border-top: 3px solid $light_grey;
  padding: 0.5rem;
}

.createOutingButton {
  margin: 1rem 0;
  background-color: $blue;
  color: white;
  width: fit-content;
}
