@import "../../../utils/responsive.scss";

.iconBacker {
  background-color: white;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  box-sizing: border-box;
  margin-top: 1rem;
  box-sizing: border-box;
  align-items: center;
}

.usersContainer {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  align-items: center;
  box-sizing: border-box;
  background-color: white;
}

.roundButton {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  outline: none;
  border: none;
  flex-direction: column;
  width: 4rem;
  font-size: 1.2rem;
  color: black !important;
}

.roundButton:hover {
  cursor: default;
  opacity: unset;
  color: black !important;
}

.editIcon {
  width: 3.5rem;
  height: 3.5rem;
  margin-bottom: 0.5rem;
}

.editIcon:hover {
  cursor: pointer;
  opacity: 0.7;
}

.iconBlocked {
  width: 3rem;
  height: 3rem;
  opacity: 0.2;
}

.iconBlocked:hover {
  cursor: auto;
}

.greyButton {
  margin: 1rem;
}

.container button:hover {
  cursor: pointer;
  opacity: 0.8;
}

.goButton {
  background-color: $blue;
  color: white !important;
  font-size: 1.4rem !important;
  font-weight: bold;
  box-sizing: border-box;
  width: 18rem !important;
  height: 4.5rem;
  display: flex;
  align-items: center;
  margin-bottom: 3rem;
  margin-top: 2rem;
  border-radius: 100rem !important;
}

.simpleHeader {
  width: 100%;
  font-size: 1.8rem;
  font-weight: bold;
  justify-content: center;
  display: flex;
}

.balloonIcon {
  height: 1.5rem;
  margin-right: 0.4rem;
}

.loading {
  font-size: 1.3rem;
  font-weight: bold;
}

.selectHeader {
  font-size: 1.6rem;
  font-weight: bold;
  margin-top: 2.5rem;
  animation: bump 5s infinite;
}

.downIcon {
  height: 2.5rem;
  box-sizing: border-box;
}

.addPeopleHeader {
  font-size: 1.6rem;
  font-weight: bold;
  margin-bottom: 2.5rem;
  animation: bump 5s infinite;
}

.bump {
  animation: bump 5s infinite;
}

.upIcon {
  height: 2.5rem;
  box-sizing: border-box;
}

.filterButton {
  margin-bottom: 1rem;
  margin-top: 1rem;
  height: 4rem;
  background-color: $button_grey !important;
  border-radius: 100rem !important;
  border: 1px solid $light_grey !important;
  font-size: 1.2rem !important;
}

.activitySearch {
  margin-bottom: 1rem;
  margin-top: 1rem;
  height: 100% !important;
}

.sideBySide {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 70%;
  height: 3.5rem;
  margin-bottom: 0.5rem;
  margin-top: 1rem;
}

.buttonSpacer {
  width: 2.5rem;
  height: 1rem;
}

.activeFiltersButton {
  background-color: $blue !important;
  color: white !important;
}

@keyframes bump {
  15% {
    scale: 1;
  }

  20% {
    scale: 1.1;
  }

  25% {
    scale: 1;
  }
}

.outingsIcon {
  width: 90%;
  margin: 1rem 0 1rem 0;
}

.infoBar {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  margin: 0.5rem 0;
}

.infoItem {
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  font-weight: bold;
  padding: 1rem;
  background-color: $card_grey;
  border-radius: 100rem;
  border: 1px solid $button_grey;
  cursor: pointer;
  /* box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px; */
  text-wrap: nowrap;
}

.infoItemCenter {
  margin: 0 0.5rem;
}

.activeInfoItem {
  background-color: $blue;
  color: white;
  font-weight: bold;
}

.infoIcon {
  height: 2rem;
  width: 2rem;
  margin-right: 1rem;
}

.activeInfoIcon {
  -webkit-filter: invert(100%);
  filter: invert(100%);
}

.outingCreatedName {
  width: 100%;
  text-align: left;
  font-size: 1.8rem;
  font-weight: bold;
  margin: 0 0 1rem 0;
}

.categoryTabContainer {
  width: 100%;
  min-width: 6rem;
  padding: 1.2rem 0.8rem;
  box-sizing: border-box;
  font-size: 1.3rem;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
  font-weight: bold;
  justify-content: center;
  display: flex;
}

.createActivityButton {
  background-color: $button_grey;
  border-radius: 100rem !important;
  border: 1px solid $light_grey !important;
  height: 4rem;
  font-size: 1.2rem !important;
}

.activitiesContainer {
  width: 100%;
  padding-top: 1rem;
}

.prompt {
  display: flex;
  width: 100%;
  justify-content: center;
  font-size: 1.3rem;
  font-weight: bold;
  align-items: center;
  animation: bump 5s infinite;
}

.arrowImgUp {
  width: 3rem;
  margin-bottom: 2rem;
  margin-left: 2rem;
}

.arrowImgDown {
  width: 3rem;
  margin-top: 2rem;
  margin-right: 2rem;
}
