@import "../../../utils/responsive.scss";

.outingInviteContainer{
    display: flex;
    /* box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px; */
    box-sizing: border-box;
    margin-bottom: 2rem;
    width: 100%;
  }

  .outingInviteInnerContainer{
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 2rem;
    background-color: $card_grey;
    border: 1px solid $light_grey;
    border-bottom-right-radius: 3px;
    border-top-right-radius: 3px;
    border-left: none;

  }

  .outingInviteHeader{
    font-size: 1.6rem;
    font-weight: bold;
  }

  .outingInviteActivity{
    font-size: 1.4rem;
    color: rgb(95, 95, 95);
  }

  .outingInviteButtons{
    display: flex;
    width: 100%;
    margin-top: 1rem;
  }

  .colorStripe{
    height: 100%;
    width: 1rem;
    box-sizing: border-box;
    border-bottom-left-radius: 3px;
    border-top-left-radius: 3px;
  }

  .buttonSpacer{
    width: 3rem;
    height: 1rem;
  }
