@import "../../../utils/responsive.scss";

.container {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all 0.1s ease-in-out;
  opacity: 0;
  box-sizing: border-box;
}

.blackout {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 0.5;
  background-color: black;
}

.popup {
  width: calc($central_span_width - 2rem);
  padding: 2rem;
  background-color: white;
  border-radius: 3px;
  position: fixed;
  box-sizing: border-box;
}
