@import "../../../utils/responsive.scss";

.login-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  width: 90%;
}

.header {
  margin: 1rem 0;
  width: 100%;
}

.appBlurb {
  padding: 2rem;
  width: 100%;
  font-size: 1.3rem;
  width: 80%;
  text-align: center;
  /* background-color: $card_grey;
  border-radius: 3px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px; */

  @include responsive("padding-top", 0, "", "", "");
}

.flavourImage {
  width: 16rem;
}

.flavourContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.formContainer {
  margin: 0 0 1.5rem 0;
  width: 100%;
  padding: 0.8rem;
  box-sizing: border-box;
  border-radius: 3px;
  background: conic-gradient(
    rgb(248, 157, 157) 0%,
    rgb(186, 255, 169) 33.33%,
    rgb(186, 255, 169) 33.33%,
    rgb(238, 183, 220) 41.66%,
    rgb(238, 183, 220) 41.66%,
    rgb(117, 204, 255) 74.99%,
    rgb(117, 204, 255) 74.99%,
    rgb(255, 236, 69) 83.32%,
    rgb(255, 236, 69) 83.32%,
    rgb(248, 157, 157) 99.99%
  );
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;

  @include responsive("background", unset, "", "", "");
  @include responsive("box-shadow", unset, "", "", "");
  @include responsive("margin-bottom", 0, "", "", "");
}

.header {
  display: flex;
  align-items: center;
  @include responsive("display", none, flex, flex, flex);
}

.logo {
  height: 2.2rem;
  margin-bottom: -0.2rem;
}

.logoTop {
  height: 6rem;
  margin-top: 1rem;
  @include responsive("margin-bottom", 0, 3rem, 3rem, 3rem);
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-radius: 10px;
  padding: 3rem;
  box-sizing: border-box;

  @include responsive("background-color", unset, #f2f2f2, #f2f2f2, #f2f2f2);
}

.login-page h2 {
  font-size: 24px;
  margin-bottom: 20px;
}

.login-form {
  border-radius: 5px;
  border-radius: 3px;
  padding: 2rem;

  @include responsive(
    "background-color",
    unset,
    $card_grey,
    $card_grey,
    $card_grey
  );
}

.form-group {
  margin-bottom: 20px;
}

label {
  display: block;
  margin-bottom: 5px;
  margin-top: 5px;
}

.loginButton {
  background-color: $blue;
  color: #fff;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 2rem;
  width: 100%;
}

.loginButton:hover {
  background-color: #0056b3;
}

.createAccountButton {
  background-color: $button_grey;
  color: rgb(0, 0, 0);
  margin-top: 1rem;
}

.forgotButton {
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 1.2rem;
  margin-top: 0.4rem;
  color: black !important;

  @include responsive(
    "background-color",
    white,
    $card_grey,
    $card_grey,
    $card_grey
  );
}

.smallButtons {
  width: 100%;
  display: flex;
  justify-content: space-between;

  @include responsive("margin-top", 0.5rem, 2rem, 2rem, 2rem);
}

.loaderContainer {
  display: flex;
}
