@import "../../../utils/responsive.scss";

.container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.pageHeader {
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-size: 3rem;
  font-weight: bold;
  align-items: center;
  background-color: $card_grey;
  border-bottom: $light_grey;
  box-sizing: border-box;
  position: fixed;
  top: 0;
  left: 0;
  padding: z;
}

.statusContainer {
  font-size: 1.8rem;
}

.locationContainer {
  font-size: 1.5rem;
  margin-bottom: 0.3rem;
  font-weight: normal;
}

.nonMediaSection {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-self: flex-start;
  align-items: center;
  width: calc(100vw - 2rem);
  max-width: calc($central_span_width - 2rem);
  background-color: white;
}

.friendsContainer {
  display: flex;
  justify-content: space-evenly;
  padding: 1rem;
  width: 100%;
}

.friendIcon {
  width: 10rem;
  height: 10rem;
  border-radius: 100px;
  border: 2px solid rgb(147, 147, 147);
  margin: 1rem 0;
  background-image: url("../../../images/usericon.png");
  background-size: contain;
}

.smallIcon {
  height: 1.5rem;
}

.sideBySide {
  display: flex;
  justify-content: space-between;
}

.simpleHeader {
  display: flex;
  justify-content: center;
}

.noContentBlurb {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  font-size: 1.4rem;
  padding: 1rem;
  box-sizing: border-box;
  align-items: center;
  text-align: center;
}

.noImagesBlurb {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  font-size: 1.4rem;
  padding: 1rem;
  box-sizing: border-box;
  align-items: center;
  text-align: center;
  font-weight: bold;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.outingBalloon {
  height: 2.5rem;
  width: 2.5rem;
  margin: 0.3rem 0.3rem;
  cursor: pointer;
}

.friendCardButtons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.friendCardButton {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 6rem;
  height: 100%;
  border-radius: 4px;
}

.friendCardButton:hover {
  opacity: 0.5;
  cursor: pointer;
}

.editButton {
  margin: 2rem 0;
}

.editIcon {
  width: 55%;
  height: 55%;
  cursor: pointer;
}

.logoutBadge {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  right: 2rem;
  font-weight: bold;
}

.logoutLogo {
  width: 1.5rem;
  height: 1.5rem;
  margin-left: 0.5rem;
}

.fixedSliderNavbar {
  position: fixed;
  top: 0;
  background-color: white;
  z-index: 2;
  width: 100vw;
  max-width: $central_span_width;
}
