.container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .rating {
    font-size: 1.2rem;
    font-weight: bold;
    text-wrap: nowrap;
  }

  .icon{
    background-size: contain;
  }