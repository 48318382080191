@import "../../../utils/responsive.scss";

.container {
  display: flex;
  flex-direction: column;
  width: 80%;
  //background-color: $card_grey;
  /* box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px; */
  border-radius: 3px;
  padding: 2rem;
  align-items: center;
  margin-top: 8rem;
  align-items: start;
  min-height: 9rem;
}

.subText {
  font-size: 1.2rem;
}

.logo{
  width: 40%;
}

.logoContainer{
  width: 100%;
  display: flex;
  justify-content: start;
}

.button {
  margin-top: 4rem;
}

.sendResetLinkElements {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.passwordResetContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.actionButton {
  background-color: $blue;
  color: white;
  margin: 1rem 0;
}

.unclickable {
  color: grey;
  margin: 1rem 0;
  cursor: not-allowed !important;
}

.unclickable:hover {
  opacity: 1;
}
