@import "../../../utils/responsive.scss";

.container {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  position: relative;
  width: 100%;
}

.input {
  width: 100%;
  background-color: rgb(229, 238, 253);
  box-sizing: border-box;
  border: none;
  padding: 1.4rem;
  font-size: 1.3rem;
  outline: none;
  border-bottom: 3px solid transparent;
  border-radius: 100rem;
}

.input:focus{
  outline: 1px solid $blue;
}

.label{
  font-size: 1.3rem;
  font-weight: bold;
  margin-left: 1rem;
}

.optionContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 1rem;
}

.optionContainer:hover {
  cursor: pointer;
  background-color: $card_grey;
}

.optionsContainer {
  position: absolute;
  left: 0;
  margin-top: 7rem;
  margin-left: 2rem;
  width: calc(100% - 4rem);
  box-sizing: border-box;
  border: 1px solid #c1c1c1;
  z-index: 1;
  max-height: 20rem;
  background-color: white;
  overflow-y: scroll;
}
