@import "../../../utils/responsive.scss";

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0rem 1rem;
  padding-bottom: 2rem;
  background-color: white;
  box-sizing: border-box;
  padding-top: 7rem;
}

.addFriend {
  background-color: $blue;
  margin: 2rem 0 2rem 0;
  color: white;
  box-shadow: none;
  border: 1px solid $light_grey;
  border-radius: 100rem;
}

.sideBySide {
  margin: 1rem 0 2rem 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  width: 100%;
}

.createOutingButton {
  margin-bottom: 1rem;
  box-shadow: none !important;
  border: 1px solid $light_grey;
  border-radius: 100rem;
}

.removeFriendButton {
  background-color: $button_grey;
  color: black;
  box-shadow: none !important;
  border: 1px solid $light_grey;
  border-radius: 100rem;
}

.unclickableButton {
  background-color: $button_grey;
  color: grey;
  cursor: default;
  box-shadow: none !important;
  margin: 2rem 0 2rem 0;
}

.unclickableButton:hover {
  cursor: default !important;
  background-color: $button_grey !important;
  opacity: 1 !important;
}

.buttonContainer{
  display: flex;
  width: 10rem;
  justify-content: space-between;

}

.buttonStyle{
  display: flex;
  flex-direction: column;
}