@import "../../../utils/responsive.scss";

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  padding-top: 1rem;
}

.upperContainer {
  background-color: white;
  width: 100%;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  margin-top: 0
}

.upperCentralContainer {
  width: calc($central_span_width - 2rem);
  margin: 1rem;
  margin-bottom: 0;
}

.refreshButton {
  margin-bottom: 1rem;
  color: white;
  background-color: $blue;
  border-radius: 3px !important;
  height: 3.5rem;
  font-size: 1.3rem;
}

.noOutingsMessage {
  padding: 2rem;
  width: 100%;
  display: flex;
  justify-content: baseline;
  text-align: center;
  box-sizing: border-box;
  flex-direction: column;
  align-items: center;
}
.noOutingsMessageText {
  width: 70%;
  font-size: 1.3rem;
}
.noOutingsMessageHeader {
  font-size: 1.8rem;
}
