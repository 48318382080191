@import "../../../utils/responsive.scss";

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow-x: hidden;
    box-sizing: border-box;
    flex-shrink: 0;
    background-color: white;
    padding: 8rem 1rem 8rem 1rem;
  }
  
.simpleInput{
    margin-bottom: 1rem;
}

.confirmEmailContainer{
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    margin-top: 5rem;

}
.confirmEmailHeader{
    font-size: 1.8rem;
}

.confirmEmailText{
    font-size: 1.3rem;
    padding: 1rem;
    text-align: center;
    margin: 0 0 2rem 0;
    width: 80%;
}

.resendEmailButton{
    background-color: $blue;
    font-weight: bold;
    color: white;
    width: 50%;
    margin-bottom: 2rem;
}

.unclickable{
    background-color: $button_grey;
    color: grey;
}

.unclickable:hover{
    cursor: not-allowed !important;
    opacity: 1 !important;
}